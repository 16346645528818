<template>
  <div class="md-layout-item md-size-100">
    <ResourcesTable />
  </div>
</template>

<script>
export default {
  components: {
    ResourcesTable: () => import("@/components/Tables/ResourcesTable"),
  },
};
</script>

<style lang="scss" scoped></style>